@import 'assets/scss/variables';

html,
body,
#root,
#root > div {
  height: 100%;
  // width: 98%;
  padding: 0;
  margin: 0;
  // font-family: 'Raleway', sans-serif;
  // font-family: 'Lato', sans-serif;
  font-family: 'Montserrat', sans-serif;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

.font-raleway {
  font-family: 'Raleway', sans-serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.bg-primary-dark {
  background-color: theme-color('primary-dark');
}

.btn-social {
  width: 32px;
  height: 32px;
  margin: auto;
  padding: 0;
  display: table-cell;
  vertical-align: middle;
}

.w-90 {
  width: 90%;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

.cursor-pointer:hover > :not(marquee) {
  cursor: pointer !important;
}

.cursor-no-drop:hover {
  cursor: no-drop !important;
}

.cursor-no-drop:hover > :not(marquee) {
  cursor: no-drop !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
.landing-city {
  position: absolute !important;
  top:0px;
  left:0px;
  * {
    border: 0px !important;
    min-height: 0px !important;
  }
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'assets/scss/pages', 'assets/scss/layout', 'assets/scss/products',
  'assets/scss/auth', 'assets/scss/applicationForm', 'assets/scss/floating';

iframe[src*="//youtube.com/"],
iframe[src*="//www.youtube.com/"] {
  display: block;
  margin: 0 auto;
}

iframe:nth-of-type(2) {
  z-index: 0 !important;
  visibility: hidden;
}